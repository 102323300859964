import React, { Component } from "react";

import styles from "./Header.module.css"


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {headerItems: [
            {
                id: "about",
                title: "About"
            },
            {
                id: "team",
                title: "Team"
            },
        ]}
    }

    render() {
        return (
            <div className={styles.header}>
                {this.state.headerItems.map((item, index) =>
                    <div className={styles.item} key={index}><a href={'#'+item.id}>{item.title}</a></div>
                )}
            </div>
        );
    }
}

export default Header;
