import React, { Component } from "react";
import Header from "./Components/Header/Header";
import Carousel from "./Components/Carousel/Carousel";
import Team from "./Components/Team/Team";
import About from "./Components/About/About";

class App extends Component {
    render() {
        return (
          <div className="App">
            <Header/>
            <Carousel/>
            <About/>
            <Team/>
          </div>
        );
    }
}

export default App;
