import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

import "./swiper.css"
import styles from "./Carousel.module.css"


class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {gameTitles: []}
    }

    async componentDidMount() {
        const res = await fetch('titles.json');
        const data = await res.json();
        this.setState({gameTitles: data});
    }

    render() {
        return (
          <Swiper
              navigation={true}
              modules={[Navigation, Autoplay]}
              centeredSlides={true}
              loop={true}
              autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
              }}
              className="carousel"
              title="We've been playing"
          >
              {this.state.gameTitles.map((item, index) =>
                  <SwiperSlide key={index}>
                      <img className={styles.image} src={item.img} alt={item.name}/>
                      <div className={styles.title}>{item.name}</div>
                      {/*<img className="image" src={item.img} alt={item.name}/>*/}
                      {/*<div className="title">{item.name}</div>*/}
                  </SwiperSlide>
              )}
          </Swiper>
        );
    }
}

export default Carousel;
