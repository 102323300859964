import React, { Component } from "react";

import styles from "./Team.module.css"


class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {teamMembers: []}
    }

    async componentDidMount() {
        const res = await fetch('team_members.json');
        const data = await res.json();
        this.setState({teamMembers: data});
    }

    render() {
        return (
            <div className={styles.members} id="team">
                {this.state.teamMembers.map((member, index) =>
                    <div key={index} className={styles.member}>
                        <div className={styles.nickname}>{member.nickname}</div>
                        <div className={styles.bio}>{member.bio}</div>
                    </div>
                )}
            </div>
        );
    }
}

export default Team;
